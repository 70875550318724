import { env } from './.env';

export const environment = {
  production: true,
  API: '/api',
  version: env['npm_package_version'],
  // Informar a URL da API para o ERP
  //  apiUrl: 'http://154.38.165.176:8084/api-erp',
  apiUrl: 'https://erp-api.chronosinfo.com.br',
  // Informar a URL do fiscal
  apiUrlFiscal: 'http://64.31.26.6:8084:5200',
  pagSeguroPublicKey: '',
  controlaAssinatura: false,
  configuracaoThema: {
    name: 'Chronos',
    logo: 'assets/images/chronos/logo.png',
    logoLogin: 'assets/images/chronos/logo_login.png',
    site: 'https://chronosinfo.com.br/',
    theme: 'theme-orange',
    layout: 'layout-orange',
  },
};
