<ng-template ngFor let-child let-i="index" [ngForOf]="root ? item : item.items">
  <li
    [ngClass]="{ 'active-menuitem': isActive(i) }"
    [class]="child.badgeStyleClass"
    *ngIf="child.visible === false ? false : true"
  >
    <a
      [href]="child.url || '#'"
      (click)="itemClick($event, child, i)"
      (mouseenter)="onMouseEnter(i)"
      class="ripplelink"
      *ngIf="!child.routerLink"
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
      [id]="child.id + i"
    >
      <i *ngIf="child.icon" [class]="child.icon"></i>
      <span>{{ child.label }}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>

      <i
        class="material-icons submenu-icon pi pi-chevron-down"
        *ngIf="child.items"
      ></i>
    </a>
    <a
      (click)="itemClick($event, child, i)"
      (mouseenter)="onMouseEnter(i)"
      class="ripplelink"
      *ngIf="child.routerLink"
      [routerLink]="child.routerLink"
      routerLinkActive="active-menuitem-routerlink"
      [routerLinkActiveOptions]="{ exact: true }"
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
      [id]="child.id + i"
    >
      <i *ngIf="child.icon" [class]="child.icon"></i>
      <span>{{ child.label }}</span>
      <span ng class="menuitem-badge" *ngIf="child.badge">{{
        child.badge
      }}</span>

      <i class="pi pi-arrow-down submenu-icon'" *ngIf="child.items"
        >keyboard_arrow_down</i
      >
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{ child.label }}</div>
    </div>

    <ul
      app-sub-menu
      [item]="child"
      *ngIf="child.items"
      [visible]="isActive(i)"
      [reset]="reset"
      [parentActive]="isActive(i)"
      [@children]="
        root
          ? isActive(i)
            ? 'visible'
            : 'hidden'
          : isActive(i)
          ? 'visibleAnimated'
          : 'hiddenAnimated'
      "
    ></ul>
  </li>
</ng-template>
