import { Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { NovoCadastroFormComponent } from './components/novo-cadastro-form/novo-cadastro-form.component';
import { NovoCadastroPublicidadeComponent } from './components/novo-cadastro-publicidade/novo-cadastro-publicidade.component';
import { NovoCadastroSuportComponent } from './components/novo-cadastro-suport/novo-cadastro-suport.component';

@Component({
  selector: 'app-novo-cadastro',
  standalone: true,
  imports: [
    NovoCadastroFormComponent,
    NovoCadastroPublicidadeComponent,
    NovoCadastroSuportComponent,
    ToastModule,
  ],
  templateUrl: './novo-cadastro.component.html',
  styleUrl: './novo-cadastro.component.scss',
})
export class NovoCadastroComponent {}
