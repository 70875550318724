import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmarResetSenhaComponent } from './confirmar-reset-senha/confirmar-reset-senha.component';
import { LoginGuard } from './login.guard';
import { LoginComponent } from './login/login.component';
import { ResetSenhaComponent } from './reset-senha/reset-senha.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'solicitar-reset-senha', component: ResetSenhaComponent },
  { path: 'reset-senha', component: ConfirmarResetSenhaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoginGuard],
})
export class SegurancaRoutingModule {}
