import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-container-manager-login',
  templateUrl: './container-manager-login.component.html',
  styleUrls: ['./container-manager-login.component.scss'],
})
export class ContainerManagerLoginComponent {
  urlLogo = environment.configuracaoThema.logoLogin;
  urlSite = environment.configuracaoThema.site;
}
