<p-dialog
  header="Pagamento"
  [(visible)]="display"
  [modal]="true"
  (onHide)="onClose()"
  [contentStyle]="{ overflow: 'visible', padding: 0 }"
>
  <ng-template pTemplate="headless">
    <div class="wrapper">
      <form class="form-container" [formGroup]="form" (submit)="onSubmit()">
        <div class="box-form">
          <div class="input-content">
            <div class="box-input">
              <label>Número do cartão</label>
              <input
                autocomplete="off"
                pInputText
                id="input-number"
                type="text"
                maxlength="19"
                placeholder="0000 0000 0000 0000"
                style="word-spacing: 8px"
                formControlName="numCartao"
                (focus)="limparCampoNum()"
                (keyup)="trocaBandeira()"
                (blur)="trocaBandeira()"
              />

              <div
                class="info visible"
                *ngIf="
                  form.get('numCartao')?.invalid &&
                  (form.get('numCartao')?.dirty ||
                    form.get('numCartao')?.touched)
                "
              >
                <span class="icon">
                  <img src="/assets/images/warning.svg" />
                </span>

                <span class="message">{{
                  getMessageError('Número do cartão', 'numCartao')
                }}</span>
              </div>
            </div>

            <div class="box-input">
              <label>Nome do titular</label>
              <input
                autocomplete="off"
                id="input-name"
                type="text"
                placeholder="Nome como está no cartão"
                pInputText
                formControlName="nome"
                (focus)="limparCampoNome()"
              />
              <div
                class="info visible"
                *ngIf="
                  form.get('nome')?.invalid &&
                  (form.get('nome')?.dirty || form.get('nome')?.touched)
                "
              >
                <span class="icon">
                  <img src="/assets/images/warning.svg" />
                </span>

                <span class="message">{{
                  getMessageError('Nome do titular', 'nome')
                }}</span>
              </div>
            </div>

            <div class="box-input-more">
              <div class="box-one">
                <label>Validade</label>
                <input
                  autocomplete="off"
                  id="input-validate"
                  maxlength="6"
                  type="text"
                  placeholder="aaaa/mm"
                  formControlName="validade"
                  (focus)="limparCampoDatas()"
                />
                <div
                  class="info visible"
                  *ngIf="
                    form.get('validade')?.invalid &&
                    (form.get('validade')?.dirty ||
                      form.get('validade')?.touched)
                  "
                >
                  <span class="icon">
                    <img src="/assets/images/warning.svg" />
                  </span>

                  <span class="message">{{
                    getMessageError('Validade', 'validade')
                  }}</span>
                </div>
              </div>

              <div class="box-two">
                <label>
                  CVV
                  <span class="icon" title="Ajuda">
                    <img src="/assets/images/help.svg" />
                  </span>
                </label>
                <input
                  autocomplete="off"
                  id="input-cvv"
                  type="password"
                  maxlength="3"
                  placeholder="cvv"
                  name="codigoCVC"
                  (focus)="limparCampoCVC()"
                  (blur)="frontCard = !frontCard"
                  maxlength="4"
                  formControlName="codigoCVC"
                />
                <div
                  class="info visible"
                  *ngIf="
                    form.get('codigoCVC')?.invalid &&
                    (form.get('codigoCVC')?.dirty ||
                      form.get('codigoCVC')?.touched)
                  "
                >
                  <span class="icon">
                    <img src="/assets/images/warning.svg" />
                  </span>

                  <span class="message">{{
                    getMessageError('CVC', 'codigoCVC')
                  }}</span>
                </div>
              </div>
            </div>

            <div class="box-input">
              <label>Email</label>
              <input
                autocomplete="off"
                type="email"
                placeholder="Email"
                pInputText
                formControlName="email"
                (focus)="limparCampoNome()"
              />
              <div
                class="info visible"
                *ngIf="
                  form.get('email')?.invalid &&
                  (form.get('email')?.dirty || form.get('email')?.touched)
                "
              >
                <span class="icon">
                  <img src="/assets/images/warning.svg" />
                </span>

                <span class="message">{{
                  getMessageError('Email', 'email')
                }}</span>
              </div>
            </div>

            <div class="box-input">
              <label>CPF/CNPJ</label>
              <input
                autocomplete="off"
                type="text"
                placeholder="CPF/CNPJ"
                pInputText
                name="documento"
                formControlName="documento"
                (focus)="limparCampoNome()"
              />
              <div
                class="info visible"
                *ngIf="
                  form.get('documento')?.invalid &&
                  (form.get('documento')?.dirty ||
                    form.get('documento')?.touched)
                "
              >
                <span class="icon">
                  <img src="/assets/images/warning.svg" />
                </span>

                <span class="message">{{
                  getMessageError('Documento', 'documento')
                }}</span>
              </div>
            </div>
            <div class="box-input">
              <label>Fone do titular</label>
              <input
                autocomplete="off"
                type="text"
                placeholder="Fone"
                pInputText
                name="fone"
                formControlName="fone"
                (focus)="limparCampoNome()"
              />
              <div
                class="info visible"
                *ngIf="
                  form.get('fone')?.invalid &&
                  (form.get('fone')?.dirty || form.get('fone')?.touched)
                "
              >
                <span class="icon">
                  <img src="/assets/images/warning.svg" />
                </span>

                <span class="message">{{
                  getMessageError('Fone', 'fone')
                }}</span>
              </div>
            </div>
          </div>

          <div class="card-content animate__animated animate__backInUp">
            <div class="card-content-box rotate" [class.rotate]="frontCard">
              <div class="box-card">
                <div class="content">
                  <div class="card-header">
                    <span class="icon"
                      ><img [src]="bandeira" style="max-width: 32px"
                    /></span>
                    <span class="icon"
                      ><img src="/assets/images/contact-less-payment.svg"
                    /></span>
                  </div>

                  <div class="card-body">
                    <div id="card-user-number" class="number-card">
                      {{ form.value.numCartao ?? '' | cardNumber }}
                    </div>

                    <div class="name-and-date">
                      <div id="card-user-name" class="name">
                        {{ form.value.nome || '(Seu nome)' }}
                      </div>
                      <div id="card-user-date" class="date">
                        {{ form.value.validade || '• • / • • • •' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-card">
                <div class="content card-2">
                  <div class="bar"></div>
                  <div class="cvv">
                    <div id="card-user-cvv" class="cvv-number">
                      {{ form.value.codigoCVC }}
                    </div>
                    <div class="cvv-text">CVV</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="rotate-card"
              class="rotate-card"
              title="Ver a outra parte do cartão"
              (click)="frontCard = !frontCard"
            >
              <img src="/assets/images/rotate.svg" width="20px" />
            </div>

            <div class="status-security">
              <span class="icon"><img src="/assets/images/shield.svg" /></span>
              <span>Seus dados estão seguros</span>
            </div>
          </div>
        </div>

        <button id="input-submit" type="submit" class="button-submit">
          Processar
        </button>
      </form>
    </div>
  </ng-template>
</p-dialog>
