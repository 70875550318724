import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { LoadingComponent } from './loading/loading.component';
import { MenuComponent } from './menu/menu.component';
import { ProfileComponent } from './profile/profile.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

import { RouterModule } from '@angular/router';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { LayoutNotificacaoVencimentoComponent } from './layout-notificacao-vencimento/layout-notificacao-vencimento.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ScrollPanelModule,
    ToastModule,
    ConfirmDialogModule,
    ButtonModule,
    RouterModule,
    ProgressSpinnerModule,
  ],
  declarations: [
    MenuComponent,
    SubMenuComponent,
    FooterComponent,
    LayoutComponent,
    LoadingComponent,
    ProfileComponent,
    ToolbarComponent,
    BreadcrumbComponent,
    LayoutNotificacaoVencimentoComponent,
  ],
})
export class LayoutModule {}
