<div class="alerta" *ngIf="mensagemVencimento || mensagemBloqueio">
  <strong *ngIf="controlaAssinatura"> {{ mensagemVencimento }}</strong>

  <span>
    <strong> {{ mensagemVencimento }}</strong>
    <br />
    <strong> {{ mensagemBloqueio }}</strong>
  </span>

  <a
    id="btn-assinar"
    pButton
    label="Assine Agora"
    data-cy="btn-novo"
    routerLink="/assinatura"
    *ngIf="controlaAssinatura"
  ></a>
</div>
