import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/seguranca/auth.service';
import { environment } from 'src/environments/environment';
import { LayoutComponent } from '../layout/layout.component';
import { criarMenu } from './menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input() reset = false;

  @Output() moveBar = new EventEmitter();

  menuItens: MenuItem[] = [];

  controlaAssinatura = environment.controlaAssinatura;

  constructor(public app: LayoutComponent, private auth: AuthService) {}

  ngOnInit() {
    this.menuItens =
      this.controlaAssinatura && this.auth.havePermission('ROLE_INADIPLENTE')
        ? [{ label: 'Dashboard', icon: 'pi pi-chart-line', routerLink: ['/'] }]
        : criarMenu(this.auth.jwtPayload?.authorities ?? []);
  }

  changeTheme(theme: any) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('theme-css')
    );
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('layout-css')
    );

    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }
}
