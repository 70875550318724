import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/layout/service/breadcrumb.service';
import { BlobErrorHttpInterceptor } from '../interception/blobErrorHttp.interceptor';
import { ErrorHandlerInterceptor } from '../interception/error-handler.interceptor';
import { LoadingInterceptor } from '../interception/loading.interceptor';
import { LoadingService } from '../layout/service/loading.service';
import { SharedModule } from '../shared/shared.module';
import { ChronosHttp } from './../seguranca/chronos-http';
import { AcessoNegadoComponent } from './acesso-negado/acesso-negado.component';
import { PaginaNaoEncontradaComponent } from './pagina-nao-encontrada/pagina-nao-encontrada.component';
import { ThemeService } from './service/theme.service';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [BrowserAnimationsModule],
  providers: [
    Title,
    ConfirmationService,
    MessageService,
    ChronosHttp,
    BreadcrumbService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingService) => new LoadingInterceptor(service),
      multi: true,
      deps: [LoadingService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    TranslateService,
    ThemeService,
  ],

  declarations: [PaginaNaoEncontradaComponent, AcessoNegadoComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.'
      );
    }
  }
}
