import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-sub-menu]',
  templateUrl: './sub-menu.component.html',
  styleUrl: './sub-menu.component.scss',
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class SubMenuComponent {
  @Input() item: any;

  @Input() root: boolean = false;

  @Input() visible?: boolean;

  @Output() clickMenu = new EventEmitter();
  @Output() resetMenu = new EventEmitter();

  @Output() moveBar = new EventEmitter();

  _reset = false;

  _parentActive = false;

  activeIndex?: number | null;

  menuHoverActive = false;

  menuItens: MenuItem[] = [];

  constructor() {}

  itemClick(event: Event, item: MenuItem, index: number): void {
    if (this.root) {
      this.menuHoverActive = !this.menuHoverActive;
      this.clickMenu.emit({ menuHoverActive: this.menuHoverActive });
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.moveBar.emit({ menuHoverActive: this.menuHoverActive });
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      this.resetMenu.emit({ resetMenu: false });
    }
  }

  onMouseEnter(index: number) {}

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
