import { Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  applyTheme(renderer: Renderer2, theme: string) {
    const root = document.documentElement;

    // Remove qualquer tema anterior
    const existingThemeLink = document.querySelector(
      'link[rel="stylesheet"][data-theme]'
    );

    if (existingThemeLink) {
      existingThemeLink.remove();
    }

    // Adiciona o logotipo
    root.style.setProperty(
      '--logo-url',
      `url('${environment.configuracaoThema.logo}')`
    );

    // Carrega o novo tema
    const link = renderer.createElement('link');

    link.rel = 'stylesheet';
    link.href = `assets/layout/css/${theme}.css`;
    link.setAttribute('data-theme', theme);

    // renderer.appendChild(document.head, link);
  }
}
