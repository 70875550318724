export const Rotas = {
  DashBoard: '/dashboard',
  Cadastros: {
    base: '/cadastros/',
    Marca: {
      search: 'produtos/marcas',
      update: 'produtos/marcas/:id',
      create: 'produtos/marcas/nova',
    },
    UnidadeProduto: {
      search: 'produtos/unidade-produtos',
      update: 'produtos/unidade-produtos/:id',
      create: 'produtos/unidade-produtos/nova',
    },
    Grupo: {
      search: 'produtos/grupos',
      update: 'produtos/grupos/:id',
      create: 'produtos/grupos/nova',
    },
    SubGrupo: {
      search: 'produtos/subGrupos',
      update: 'produtos/subGrupos/:id',
      create: 'produtos/subGrupos/nova',
    },
    Produto: {
      search: 'produtos',
      update: 'produtos/:id',
      create: 'produtos/novo',
    },
    Cor: {
      search: 'produtos/cores',
      update: 'produtos/cores/:id',
      create: 'produtos/cores/nova',
    },
    Tamanho: {
      search: 'produtos/tamanhos',
      update: 'produtos/tamanhos/:id',
      create: 'produtos/tamanhos/nova',
    },
    Almoxarifado: {
      search: 'produtos/almoxarifados',
      update: 'produtos/almoxarifados/:id',
      create: 'produtos/almoxarifados/nova',
    },
    TabelaNutricional: {
      search: 'produtos/tabelaNutricional',
      update: 'produtos/tabelaNutricional/:id',
      create: 'produtos/tabelaNutricional/nova',
    },
    Empresa: {
      search: 'administrativos/empresas',
      update: 'administrativos/empresas/:id',
    },
    Contador: {
      update: 'administrativos/contador',
    },
    Papel: {
      search: 'administrativos/papeis',
      update: 'administrativos/papeis/:id',
      create: 'administrativos/papeis/novo',
    },
    Usuario: {
      search: 'administrativos/usuarios',
      update: 'administrativos/usuarios/:id',
      create: 'administrativos/usuarios/novo',
    },
    Pessoa: {
      search: 'pessoal/pessoas',
      update: 'pessoal/pessoas/:id',
      create: 'pessoal/pessoas/nova',
    },
    Colaborador: {
      search: 'pessoal/colaboradores',
      update: 'pessoal/colaboradores/:id',
      create: 'pessoal/colaboradores/novo',
    },
    Setor: {
      search: 'pessoal/setores',
      update: 'pessoal/setores/:id',
      create: 'pessoal/setores/novo',
    },
    Cargo: {
      search: 'pessoal/cargos',
      update: 'pessoal/cargos/:id',
      create: 'pessoal/cargos/novo',
    },
    Atividade: {
      search: 'comercial/atividades',
      update: 'comercial/atividades/:id',
      create: 'comercial/atividades/nova',
    },
    ProdutoPromocional: {
      search: 'comercial/produtos-promocionais',
      update: 'comercial/produtos-promocionais/:id',
      create: 'comercial/produtos-promocionais/novo',
    },
    Regiao: {
      search: 'comercial/regioes',
      update: 'comercial/regioes/:id',
      create: 'comercial/regioes/nova',
    },
    SituacaoClienteFornecedor: {
      search: 'comercial/situacoes-cliente-fornecedor',
      update: 'comercial/situacoes-cliente-fornecedor/:id',
      create: 'comercial/situacoes-cliente-fornecedor/nova',
    },
    Cliente: {
      search: 'comercial/clientes',
      update: 'comercial/clientes/:id',
      create: 'comercial/clientes/novo',
    },
    Vendedor: {
      search: 'comercial/vendedores',
      update: 'comercial/vendedores/:id',
      create: 'comercial/vendedores/novo',
    },
    TabelaPreco: {
      search: 'comercial/tabelas-preco',
      update: 'comercial/tabelas-preco/:id',
      create: 'comercial/tabelas-preco/nova',
    },
    CondicaoPagamento: {
      search: 'comercial/condicoes-pagamento',
      update: 'comercial/condicoes-pagamento/:id',
      create: 'comercial/condicoes-pagamento/nova',
    },
    Fornecedor: {
      search: 'comercial/fornecedores',
      update: 'comercial/fornecedores/:id',
      create: 'comercial/fornecedores/novo',
    },
    TipoPag: {
      search: 'comercial/tipo-pagamento',
      update: 'comercial/tipo-pagamento/:id',
      create: 'comercial/tipo-pagamento/novo',
    },
    AgenciaBanco: {
      search: 'financeiro/agencias',
      update: 'financeiro/agencias/:id',
      create: 'financeiro/agencias/nova',
    },
    ContaCaixa: {
      search: 'financeiro/contas',
      update: 'financeiro/contas/:id',
      create: 'financeiro/contas/nova',
    },
    TalonarioCheque: {
      search: 'financeiro/taloes',
      update: 'financeiro/taloes/:id',
      create: 'financeiro/taloes/novo',
    },
    Cheque: {
      search: 'financeiro/cheques',
      update: 'financeiro/cheques/:id',
      create: 'financeiro/cheques/novo',
    },
    NaturezaFinanceira: {
      search: 'financeiro/naturezas-financeira',
      update: 'financeiro/naturezas-financeira/:id',
      create: 'financeiro/naturezas-financeira/nova',
    },
    PlanoNaturezaFinanceira: {
      search: 'financeiro/planos-natureza-financeira',
      update: 'financeiro/planos-natureza-financeira/:id',
      create: 'financeiro/planos-natureza-financeira/nova',
    },
    TipoPagamento: {
      search: 'financeiro/tipos-pagamento',
      update: 'financeiro/tipos-pagamento/:id',
      create: 'financeiro/tipos-pagamento/novo',
    },
    TipoRecebimento: {
      search: 'financeiro/tipos-recebimento',
      update: 'financeiro/tipos-recebimento/:id',
      create: 'financeiro/tipos-recebimento/novo',
    },
    OperadoraCartao: {
      search: 'financeiro/operadoras-cartao',
      update: 'financeiro/operadoras-cartao/:id',
      create: 'financeiro/operadoras-cartao/nova',
    },
    Equipamento: {
      search: 'os/equipamentos',
      update: 'os/equipamentos/:id',
      create: 'os/equipamentos/novo',
    },
    Tecnico: {
      search: 'os/tecnicos',
      update: 'os/tecnicos/:id',
      create: 'os/tecnicos/novo',
    },
    Caixa: {
      search: 'pdv/caixas',
      update: 'pdv/caixas/:id',
      create: 'pdv/caixas/novo',
    },
    Operador: {
      search: 'pdv/operadores',
      update: 'pdv/operadores/:id',
      create: 'pdv/operadores/novo',
    },
    Motorista: {
      search: 'transporte/motorista',
      update: 'transporte/motorista/:id',
      create: 'transporte/motorista/nova',
    },
    Transportadora: {
      search: 'transporte/transportadora',
      update: 'transporte/transportadora/:id',
      create: 'transporte/transportadora/nova',
    },
    Veiculo: {
      search: 'transporte/veiculos',
      update: 'transporte/veiculos/:id',
      create: 'transporte/veiculos/novo',
    },
  },
  Comercial: {
    base: '/comercial/',
    PreVenda: {
      search: 'pre-vendas',
      update: 'pre-vendas/:id',
      create: 'pre-vendas/nova',
      cupom: 'pre-vendas/cupom/:id',
    },
    Devolucoes: {
      search: 'devolucoes-venda',
      update: 'devolucoes-venda/:id',
      create: 'devolucoes-venda/nova',
      cupom: 'devolucoes-venda/cupom/:id',
    },
    Orcamento: {
      search: 'orcamentos',
      update: 'orcamentos/:id',
      cupom: 'orcamentos/cupom/:id',
      create: 'orcamentos/novo',
    },
    Pedido: {
      search: 'pedidos',
      update: 'pedidos/:id',
      cupom: 'pedidos/cupom/:id',
      create: 'pedidos/novo',
    },
    Os: {
      search: 'os',
      update: 'os/:id',
      create: 'os/nova',
      cupom: 'os/cupom/:id',
    },
    Nfe: {
      search: 'nfe',
      update: 'nfe/:id',
      create: 'nfe/nova',
    },

    Nfse: {
      search: 'issqn/nfse',
      update: 'issqn/nfse/:id',
      create: 'issqn/nfse/nova',
    },
    Servico: {
      search: 'issqn/servicos',
      update: 'issqn/servicos/:id',
      create: 'issqn/servicos/novo',
    },
    RomaneioEntrega: {
      search: 'transporte/romaneio-entrega',
      update: 'transporte/romaneio-entrega/:id',
      create: 'transporte/romaneio-entrega/novo',
    },
    Mdfe: {
      search: 'transporte/mdfe',
      update: 'transporte/mdfe/:id',
      create: 'transporte/mdfe/novo',
    },

    Diversos: {
      FormacaoPreco: {
        search: 'diversos/formacao-preco',
        update: 'diversos/formacao-preco/:id',
        create: 'diversos/formacao-preco/nova',
      },
    },
    Pdv: {
      search: 'pdv/vendas',
      cupom: 'pdv/cupom/:id',
    },

    Caixa: {
      Movimento: {
        search: 'caixa/movimentos-caixa',
        open: 'caixa/movimentos-caixa/abertura',
        update: 'caixa/movimentos-caixa/:id',
        create: 'caixa/movimentos-caixa/nova',
        cupom: 'movimentos-caixa/cupom/:id',
        pdf: 'movimentos-caixa/pdf/:id',
      },
    },
  },
  Financeiro: {
    base: '/financeiro/',
    MovimentosCredito: {
      search: 'movimentos-credito',
      update: 'movimentos-credito/:id',
      create: 'movimentos-credito/novo',
      cupom: 'movimentos-credito/cupom/:id',
    },
    LancamentoPagar: {
      search: 'lancamentos-pagar',
      update: 'lancamentos-pagar/:id',
      create: 'lancamentos-pagar/novo',
    },
    LancamentoReceber: {
      search: 'lancamentos-receber',
      update: 'lancamentos-receber/:id',
      create: 'lancamentos-receber/novo',
      cupom: 'lancamentos-receber/cupom/:id',
    },
    Recebimento: {
      search: 'recebimento',
    },
    EmissaoCheque: {
      search: 'cheques-emissoes',
      update: 'cheques-emissoes/:id',
      create: 'cheques-emissoes/nova',
    },
    CusodiaCheques: {
      search: 'cheques-custodia',
      update: 'cheques-custodia/:id',
      create: 'cheques-custodia/nova',
    },
  },
  Suprimentos: {
    base: '/suprimentos/',
    Estoque: {
      Entrada: {
        search: 'estoque/entradas',
        update: 'estoque/entradas/:id',
        create: 'estoque/entradas/nova',
      },
      Transferencias: {
        search: 'estoque/transferencias',
        update: 'estoque/transferencias/:id',
        create: 'estoque/transferencias/nova',
      },
      RequisicaoInterna: {
        search: 'estoque/requisicoes-interna',
        update: 'estoque/requisicoes-interna/:id',
        create: 'estoque/requisicoes-interna/nova',
      },
      Ajuste: {
        create: 'estoque/ajustes',
      },
      Balanco: {
        search: 'estoque/balancos',
        update: 'estoque/balancos/:id',
        create: 'estoque/balancos/novo',
      },
      Reajuste: {
        search: 'estoque/reajustes',
        update: 'estoque/reajustes/:id',
        create: 'estoque/reajustes/nova',
      },
    },
    Compras: {
      FornecedorProduto: {
        search: 'compras/fornecedores-produtos',
        update: 'compras/fornecedores-produtos/:id',
        create: 'compras/fornecedores-produtos/novo',
      },
      RequisicaoCompra: {
        search: 'compras/requisicoes-compras',
        update: 'compras/requisicoes-compras/:id',
        create: 'compras/requisicoes-compras/nova',
      },
      Pedido: {
        search: 'compras/pedidos',
        update: 'compras/pedidos/:id',
        create: 'compras/pedidos/novo',
      },
      Cotacao: {
        search: 'compras/cotacoes',
        update: 'compras/cotacoes/:id',
        create: 'compras/cotacoes/nova',
      },

      Devolucao: {
        search: 'compras/devolucao',
        update: 'compras/devolucao/:id',
        create: 'compras/devolucao/nova',
      },
    },
  },
  Fiscal: {
    base: '/fiscal/',
    ConfigurarTributacao: {
      search: 'tributacao/configuracoes',
      update: 'tributacao/configuracoes/:id',
      create: 'tributacao/configuracoes/nova',
    },
    GrupoTributario: {
      search: 'tributacao/grupos-tributario',
      update: 'tributacao/grupos-tributario/:id',
      create: 'tributacao/grupos-tributario/novo',
    },
    OperacaoFiscal: {
      search: 'tributacao/operacoes-fiscais',
      update: 'tributacao/operacoes-fiscais/:id',
      create: 'tributacao/operacoes-fiscais/nova',
    },
    Ibpt: {
      search: 'tributacao/ibpt',
    },
  },
  Gerencial: {
    base: '/gerencial/',
    Auditoria: {
      search: 'auditorias',
      preview: 'auditorias/:id',
    },
    Retricao: {
      search: 'restricoes',
      update: 'restricoes/:id',
      create: 'restricoes/nova',
    },
  },
  Configuracao: {
    base: '/configuracoes/',
    Certificado: 'certificado',
    Os: 'os',

    Nfe: {
      search: 'nfe',
      update: 'nfe/:id',
      create: 'nfe/nova',
    },

    Parametro: 'parametros',
    ModeloDocumentoFiscal: {
      search: 'modelos-documento-fiscais',
      update: 'modelos-documento-fiscais/:id',
      create: 'modelos-documento-fiscais/novo',
    },
    Pdv: {
      search: 'pdv',
      update: 'pdv/:id',
      create: 'pdv/nova',
    },
    ConversaoCfop: {
      search: 'conversoes-cfop',
      update: 'conversoes-cfop/:id',
      create: 'conversoes-cfop/nova',
    },
    ConversaoCst: {
      search: 'conversoes-cst',
      update: 'conversoes-cst/:id',
      create: 'conversoes-cst/nova',
    },
  },
  Relatorios: {
    base: '/relatorios/',
    Cadastros: {
      Produto: 'cadastros/proutos',
      Clientes: 'cadastros/clientes',
      Fornecedores: 'cadastros/fornecedores',
    },
    NF: {
      downloadXml: 'comercial/download-xmls',
      relacaoNfe: 'comercial/issqn/nfe/relacao',
      relacaoNfse: 'comercial/issqn/nfse/relacao',
    },
    Estoque: {
      Entradas: 'estoque/entradas',
      Itens: 'estoque/itens-estoque-baixo',
      Inventario: 'estoque/inventario',
      Movimento: 'estoque/movimento-produto',
    },
    Financeiro: {
      Apagar: 'financeiro/contas-pagar',
      Areceber: 'financeiro/contas-receber',
      Ficha: 'financeiro/ficha-financeira',
      ParcelaQuitada: 'financeiro/parcelas-quitadas',
    },
    Movimento: {
      Lancamento: 'movimento/lancamentos',
    },
    Os: {
      Relacao: 'os/relacao',
    },
    Vendas: {
      Comissões: 'vendas/comissoes',
      Clientes: 'vendas/clientes-mais-compram',
      Forma: 'vendas/produtos-forma-pagamento',
      Produtos: 'vendas/produtos-mais-vendidos',
      Ranck: 'vendas/ranck-produtos',
      Vendas: 'vendas/vendas',
      Lucratividade: 'vendas/lucratividade',
    },
  },
};
