import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/seguranca/auth.service';
import { anoMesValidator, getErrorMsg } from 'src/app/shared/helper/validators';
import { CardNumberPipe } from 'src/app/shared/pipes/card-number.pipe';
import { RegistroService } from 'src/app/view/novo-cadastro/services/registro.service';
import { EncryptRequest, InfoCard } from '../../model/dashboard';
import { PagSeguroService } from '../../service/pag-seguro.service';
import { SharedModule } from './../../../../shared/shared.module';

@Component({
  selector: 'app-dashboard-checkout-assinatura',
  standalone: true,
  imports: [CardNumberPipe, SharedModule],
  templateUrl: './dashboard-checkout-assinatura.component.html',
  styleUrl: './dashboard-checkout-assinatura.component.scss',
})
export class DashboardCheckoutAssinaturaComponent {
  @Input() display = false;
  @Input() idpacote = -1;
  @Output() eventCloser = new EventEmitter();

  bandeira: string = '/assets/images/visa.svg';

  bandeiraMaster: string = '/assets/images/mastercard.png';
  bandeiraVisa: string = '/assets/images/visa.svg';
  bandeiraElo: string = '/assets/images/elo.png';

  frontCard = true;

  patterns: any = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard:
      /^(5[1-5][0-9]{14}|2(2[2-9][0-9]{12}|[3-6][0-9]{13}|7[01][0-9]{12}|720[0-9]{12}))$/,
    amex: /^3[47][0-9]{13}$/,
    discover:
      /^6(011|5[0-9]{2}|4[4-9][0-9]|22[1-9][0-9]{3}|22[0-9]{3}|[0-5][0-9]{4})[0-9]{12}$/,
    elo: /^((4011|4312|4389|4514|4576|5041|6277|6362|6363)|506[6-9]|509[0-9]|650[0-9]|651[6-9]|6550|6551)[0-9]{12}$/,
  };

  bandeiras: any = {
    visa: '/assets/images/visa.svg',
    mastercard: '/assets/images/mastercard.png',
    discover: '/assets/images/discover.png',
    elo: '/assets/images/elo.png',
    amex: '/assets/images/amex.png',
  };

  form = this.createForm();

  constructor(
    private builder: FormBuilder,
    private pagSeguroService: PagSeguroService,
    private registerService: RegistroService,
    private authService: AuthService
  ) {}

  limparCampoNome() {
    this.form.value.nome = '';
  }

  limparCampoNum() {
    this.form.value.numCartao = '';
  }

  limparCampoDatas() {
    this.form.value.validade = '';
  }

  limparCampoCVC() {
    this.frontCard = !this.frontCard;
    this.form.value.codigoCVC = '';
  }

  trocaBandeira() {
    let brandType = '';

    for (const cardType in this.patterns) {
      const regex = this.patterns[cardType];

      if (regex.test(this.form.value.numCartao)) {
        brandType = cardType;
        break;
      }
    }

    this.bandeira = this.bandeiras[brandType];
  }

  onSubmit() {
    if (this.form.invalid) return;

    const infoCard = this.form.value;

    const expMonth = infoCard.validade?.substring(4, 6);
    const expYear = infoCard.validade?.substring(0, 4);

    const encript: Partial<EncryptRequest> = {
      holder: infoCard.nome,
      number: infoCard.numCartao,
      securityCode: infoCard.codigoCVC,
      expMonth,
      expYear,
    };

    console.log('Dados a serem encripitaod', encript);

    const cript = this.pagSeguroService.doEncrypt(encript);

    const infoCardRequest: Partial<InfoCard> = {
      documento: infoCard.documento,
      email: infoCard.email,
      cartaoEncriptado: cript,
      idpacote: this.idpacote,
      idtenant: this.authService.jwtPayload?.idtenant,
      nome: infoCard.nome,
      fone: infoCard.fone,
    };

    this.registerService.purchasePlan(infoCardRequest).subscribe({
      next: () => {
        this.authService.obterNovoAccessToken();
      },
    });
  }

  onClose() {
    this.display = false;
    this.eventCloser.emit();
  }

  hasError(): boolean {
    return Boolean(
      this.form?.invalid && (this.form?.dirty || this.form?.touched)
    );
  }

  getMessageError(label: string, field: string) {
    const control = this.form.get(field);

    for (const propertyName in control?.errors) {
      if (control?.errors.hasOwnProperty(propertyName)) {
        return getErrorMsg(
          label,
          propertyName as any,
          control.errors[propertyName]
        );
      }
    }
    return `${label} obrigatorio`;
  }

  private createForm() {
    return this.builder.group({
      nome: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(5)],
        nonNullable: true,
      }),
      numCartao: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      validade: new FormControl<string>('', {
        validators: [Validators.required, anoMesValidator()],
        nonNullable: true,
      }),
      codigoCVC: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true,
      }),
      documento: new FormControl<string>('', {
        validators: [Validators.required, Validators.minLength(11)],
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
      }),
      fone: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }
}
