import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Biblioteca } from 'src/app/util/biblioteca';
import { isPresent } from './funcoes';

export type TypeValidation =
  | 'required'
  | 'minlength'
  | 'maxlength'
  | 'min'
  | 'max'
  | 'cepInvalido'
  | 'emailInvalido'
  | 'email'
  | 'cpf'
  | 'cnpj'
  | 'invalidAnoMes'
  | 'strongPassword';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;

    const EMAIL_REGEXP =
      /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5})$/;
    const valid = EMAIL_REGEXP.test(control.value);
    return valid ? null : { emailInvalido: { value: control.value } };
  };
}

export function anoMesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const value = control.value as string;

    const regex = /^(19|20)\d{2}(0[1-9]|1[0-2])$/;

    if (!regex.test(value)) {
      return { invalidAnoMes: true };
    }

    const ano = parseInt(value.substring(0, 4), 10);
    const mes = parseInt(value.substring(4, 6), 10);

    const anoAtual = new Date().getFullYear();

    if (ano < 1900 || ano < anoAtual) {
      return { invalidAnoMes: true };
    }

    if (mes < 1 || mes > 12) {
      return { invalidAnoMes: true };
    }

    return null;
  };
}

export function minLengthArray(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value.length >= min ? null : { MinLengthArray: true };
  };
}

export function cpfValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const cpf = (control.value || '').replace(/\D/g, '').toString();
    const valid = cpf.length === 0 || Biblioteca.cpfValido(cpf);
    return valid ? null : { cpf: { value: control.value } };
  };
}

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) return null;

    const cnpj = (control.value || '').replace(/\D/g, '').toString();
    const valid = cnpj.length === 0 || Biblioteca.cnpjValido(cnpj);
    return valid ? null : { cnpj: { value: control.value } };
  };
}

export function cpfCnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cpfCnpj = (control.value || '').replace(/\D/g, '').toString();
    const isCpf = cpfCnpj.length <= 11;
    const valid =
      cpfCnpj.length === 0
        ? true
        : cpfCnpj.length > 0 && cpfCnpj.length <= 11
        ? Biblioteca.cpfValido(cpfCnpj)
        : Biblioteca.cnpjValido(cpfCnpj);
    const error = isCpf
      ? { cpf: { value: control.value } }
      : { cnpj: { value: control.value } };
    return valid ? null : error;
  };
}

export function cepValidator(control: UntypedFormControl | AbstractControl) {
  const cep = control.value;
  if (cep && cep !== '') {
    const validacep = /^[0-9]{8}$/;
    return validacep.test(cep) ? null : { cepInvalido: true };
  }
  return null;
}

export function matchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (abstractControl: AbstractControl) => {
    const control = abstractControl.get(controlName);
    const matchingControl = abstractControl.get(matchingControlName);

    if (
      matchingControl!.errors &&
      !matchingControl!.errors?.[matchingControlName]
    )
      return null;

    if (control!.value !== matchingControl!.value) {
      const error = { confirmedValidator: 'Confirmação de senha invalida' };
      matchingControl!.setErrors(error);
      return error;
    } else {
      matchingControl!.setErrors(null);
      return null;
    }
  };
}

export function patternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const valid = regex.test(control.value);

    return valid ? null : error;
  };
}

export function strongPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 8;

    const passwordValid =
      hasUpperCase &&
      hasLowerCase &&
      hasNumeric &&
      hasSpecialChar &&
      isValidLength;

    return !passwordValid
      ? {
          strongPassword: {
            hasUpperCase: hasUpperCase,
            hasLowerCase: hasLowerCase,
            hasNumeric: hasNumeric,
            hasSpecialChar: hasSpecialChar,
            isValidLength: isValidLength,
          },
        }
      : null;
  };
}

export function getErrorMsg(
  fieldName: string,
  validatorName: TypeValidation,
  validatorValue?: any
) {
  const config: {
    [key in TypeValidation]: string | { [key: string]: string };
  } = {
    required: `${fieldName} é obrigatório.`,
    minlength: `${fieldName} precisa ter no mínimo ${validatorValue?.requiredLength} caracteres.`,
    maxlength: `${fieldName} precisa ter no máximo ${validatorValue?.requiredLength} caracteres.`,
    min: `${fieldName} precisa ter valor mínimo ${validatorValue?.min}.`,
    max: `${fieldName} precisa ter valor máximo ${validatorValue?.max}.`,
    cepInvalido: 'CEP inválido.',
    emailInvalido: 'Email inválido.',
    email: 'Email inválido.',
    cpf: 'CPF inválido.',
    cnpj: 'CNPJ inválido.',
    invalidAnoMes: 'Formato inválido. Exemplo válido: 202412',
    strongPassword: {
      hasUpperCase: 'Deve ter uma letra maiúscula.',
      hasLowerCase: 'Deve ter uma letra minúscula.',
      hasNumeric: 'Deve ter um número.',
      hasSpecialChar: 'Deve ter um caractere especial como !@#$%^&*.',
      isValidLength: 'Deve ter pelo menos 8 caracteres.',
    },
  };
  console.log(validatorValue);
  if (
    validatorName === 'strongPassword' &&
    typeof validatorValue === 'object'
  ) {
    const passwordErrors = config.strongPassword as { [key: string]: string };
    const errorMessages = Object.keys(validatorValue)
      .filter((key) => !validatorValue[key])
      .map((key) => passwordErrors[key]);

    return errorMessages;
  }

  const errorMsg = config[validatorName];

  if (typeof errorMsg === 'string') return errorMsg;

  console.log(validatorName);
  return `${fieldName} é inválido.`;
}
