<div class="container">
  <div class="content">
    <div class="separator"></div>
    <h2 class="title p-text-center">
      <strong> Escolha plano ideal para o seu negócio </strong>
      &nbsp;
    </h2>
  </div>

  <div class="content-button">
    <button
      [class.active]="activeButtonYearPlan"
      class="filter-button"
      id="yearly"
      (click)="onSelectYearPlan()"
    >
      <span class="plan-discount">-40%</span> Anual
    </button>
    <button
      [class.active]="activeButtonSemesterPlan"
      class="filter-button"
      id="semiyearly"
      (click)="onSelectSemesterPlan()"
    >
      <span class="plan-discount">-20%</span> Semestral
    </button>
    <button
      [class.active]="activeButtonMotherPlan"
      class="filter-button"
      id="monthly"
      (click)="onSelectMotherPlan()"
    >
      Mensal
    </button>
  </div>

  <div class="content-plan p-pb-5">
    <div class="p-grid">
      <ng-template ngFor let-plan [ngForOf]="planosSelecionados">
        <app-dashboard-plano
          [plano]="plan"
          (eventPurchasePlan)="onPurchasePlan($event)"
        ></app-dashboard-plano>
      </ng-template>
    </div>
  </div>
</div>

<app-dashboard-checkout-assinatura
  [display]="display"
  [idpacote]="idplan"
  (eventCloser)="display = false"
></app-dashboard-checkout-assinatura>
